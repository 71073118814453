<template>
    <!-- 询柜订单 -->
    <div class="EnquiryCabinet">
        <div class="EnquiryServer">
            <!-- 订单号 -->
            <div class="orderNumber">
                <input type="text" placeholder="订单号" v-model="orderNumber" @input="inputNum1">
            </div>
            <!-- 订单状态 -->
            <div class="orderStatus">
                <el-select v-model="StatusValue" placeholder="订单状态" @change="handleOrderStatus(StatusValue)">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <!-- 下单范围日期 -->
            <div class="orderRangeDate">
                <!-- <el-date-picker prefix-icon="" v-model="value1" type="date" placeholder="下单范围日期"
                    value-format="yyyy-MM-dd" @change="handleOrderRangeDate(value1)">
                </el-date-picker> -->

                <el-date-picker v-model="value1" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                    :picker-options="pickerOptions" end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <!-- 搜索按钮 -->
            <div class="server_Btn" @click=handleServer><i class="el-icon-search"></i><span>搜索</span></div>
        </div>

        <div class="tables">
            <el-table :data="orderDetailList" style="width: 100%" border :header-row-style="{ height: '50px' }"
                :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '56px' }">
                <el-table-column prop="id" label="订单号" width="200">
                </el-table-column>
                
                <el-table-column prop="cate" label="订单类型" width="150">
                </el-table-column>
                <el-table-column prop="stateS" label="状态" width="150">
                    <template slot-scope="scope">
                        <p v-if="scope.row.state == 0">询价中</p>
                        <p v-if="scope.row.state == 1">已报价</p>
                        <p v-if="scope.row.state == 2">已下单</p>
                    </template>
                </el-table-column>
                <el-table-column prop="departure" label="起运港" width="150">
                </el-table-column>
                <el-table-column prop="destination" label="目的港" width="150">
                </el-table-column>
                <el-table-column prop="cupboard" label="集装箱类型" width="150">
                    <template slot-scope="scope">
                        <p>{{ scope.row.cupboard || "无" }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="cupboardnum" label="箱量" width="150">
                    <template slot-scope="scope">
                        <p>{{ scope.row.cupboardnum || "无" }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="件毛体" width="250">
                    <template slot-scope="scope">
                        <div v-if="scope.row.pkg">
                            <span>件数：{{ scope.row.pkg }}，</span>
                            <span>重量：{{ scope.row.kgs }}，</span>
                            <span>体积：{{ scope.row.cbm }}</span>
                        </div>
                        <div v-else>无</div>
                    </template>
                </el-table-column>
                <el-table-column prop="toDoorAddress" label="到门地址" width="150">
                    <template slot-scope="scope">
                        <p>{{ scope.row.finalAddr || '无' }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="oceanFreight" label="总价" width="150">
                    <template slot-scope="scope">
                        <p>
                            <span>{{ scope.row.currency | getCurrency }}</span>
                            {{ scope.row.price * scope.row.cupboardnum || scope.row.price || '请等待报价' }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="userNote" label="其他费用" width="120">
                </el-table-column>
                <el-table-column label="货好时间" width="200">
                    <template slot-scope="scope">
                        <div v-if="scope.row.pickUpTimeBeg">
                            {{ scope.row.pickUpTimeBeg.split(" ")[0] }}
                            &nbsp;\&nbsp;
                            {{ scope.row.pickUpTimeEnd.split(" ")[0] }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="期望到达时间" width="200">
                    <template slot-scope="scope">
                        <div v-if="scope.row.expectTimeBeg">
                            {{ scope.row.expectTimeBeg.split(" ")[0] }}
                            &nbsp;\&nbsp;
                            {{ scope.row.expectTimeEnd.split(" ")[0] }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="shippingDate" label="船期" width="200">
                    <template slot-scope="scope">
                        <div v-if="scope.row.cate == '海运订单'">
                            <p>{{ scope.row.shippingDate || '暂无' }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="note" label="备注" width="120">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.note || "无" }}
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="createTime" label="下单时间" width="120">
                    <template slot-scope="scope">
                        <p>{{ scope.row.createTime.split(' ')[0] }}</p>
                    </template>
                </el-table-column> -->


                <el-table-column fixed="right" label="操作" width="240" class-name="nana">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">立即下单</el-button>
                        <el-button type="text" class="detailOrder" @click="handleDetailOrder(scope.row)">详情
                        </el-button>
                        <el-button @click="handleCancelTheOrder(scope.row)" type="text" size="small">撤单</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>
        </div>


    </div>
</template>
<!-- OceanShipping_Component.vue -->
<script>
import axios from 'axios'
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "EnquiryCabinet_Component.vue",
    data() {
        return {
            StatusValue: -1,
            orderDetailList: [],//询价记录
            orderNumber: "", //订单号
            orderType: "",
            otherExpenses: "",//下单时间
            options: [{
                value: -1,
                label: '查全部'
            }, {
                value: 0,
                label: '询价中'
            }, {
                value: 1,
                label: '已报价'
            }, {
                value: 2,
                label: '已下单'
            },],
            value1: "",
            tableData: [{
                orderNumber: null, //订单号
                orderType: null,   //订单类型
                stateS: null,      //状态
                departure: '宁波',   //起运港
                destination: '汉堡', //目的港
                containerType: null,//集装箱类型
                toDoorAddress: null,//到门地址
                oceanFreight: null, //海运费用
                otherExpenses: null,//其他费用
                orderTime: null     //下单时间
            }, {
                orderNumber: null, //订单号
                orderType: null,   //订单类型
                stateS: null,      //状态
                departure: '宁波',   //起运港
                destination: '汉堡', //目的港
                containerType: null,//集装箱类型
                toDoorAddress: null,//到门地址
                oceanFreight: null, //海运费用
                otherExpenses: null,//其他费用
                orderTime: null     //下单时间
            }
            ],
            inquiryListPageNo: 1, //记录页
            states: -1,
            formData: {
                pageNo: 1, //当前页
            },
            paging: {
                total: 0,
                size: 5,
            },

            begTime: '', //开始时间
            endTimes: '', //结束时间

            pickerOptions: {  // 对象
                // 禁用时间
                selectableRange: '10:00:00- 23:59:59', // 动态写法在初始化时 对象.的方式更改
                disabledDate: (time) => {  // 禁用日期
                    let nowData = new Date()
                    nowData = new Date(nowData.setDate(nowData.getDate() - 1))
                    return time < nowData
                }
            }
        }
    },
    created() {
        this.getinquiryList()
    },
    methods: {

        inputNum1(e) {  //限制输入数字
            this.orderNumber = e.target.value;
            this.orderNumber = this.orderNumber.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.orderNumber = this.orderNumber.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.orderNumber = this.orderNumber.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.orderNumber = this.orderNumber.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        },

        getinquiryList() {
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/inquiry/inquiryList`, {
                //参数列表
                params: { pageNo: this.inquiryListPageNo, state: this.states, cate: "" },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result);
                    this.orderDetailList = res.data.result.records;
                    this.paging.total = res.data.result.total;
                })
                .catch(err => {
                    console.error(err);
                })
        },

        handleServer() { //点击搜索
            var token = sessionStorage.getItem("token")
            // console.log("$$$", typeof (this.orderNumber)); //订单号
            // console.log(typeof (this.StatusValue));  //状态
            // console.log(this.value1);
            if (this.value1) {
                this.begTime = this.value1[0];  //开始时间
                this.endTimes = this.value1[1]; //结束时间
            } else {
                this.begTime = '';  //开始时间
                this.endTimes = ''; //结束时间
            }
            // console.log(this.value1);

            axios.get(`${baseUrl}/inquiry/inquiryList`, {
                params: {
                    id: this.orderNumber,
                    pageNo: this.inquiryListPageNo,
                    state: this.StatusValue,
                    begTime: this.begTime || '',
                    endTimes: this.endTimes || ''
                },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    // console.log(res.data.result)
                    this.paging.total = res.data.result.total;
                    this.orderDetailList = res.data.result.records;
                })
                .catch(err => {
                    console.error(err);
                })

        },
        handleOrderStatus(val) {
            this.orderType = val
        },
        handleOrderRangeDate(val) {
            this.otherExpenses = this.value
        },
        handleClick(row) {
            if (row.state == 0) {
                this.$message({
                    showClose: true,
                    message: '请等待报价',
                    type: 'warning'
                });
            } else if (row.state == 0) {
                this.$message({
                    showClose: true,
                    message: '已下单',
                    type: 'warning'
                });
            }
            else {
                if (row.cate === "海运订单") {
                    // console.log("1");
                    this.$router.push({
                        path: "/EnquiryCabinet/BookingSpaceoceanShipping",
                        query: {
                            id: row.id
                        }
                    })
                } else {
                    this.$router.push({
                        path: "/EnquiryCabinet/BookingSpace",
                        query: {
                            id: row.id
                        }
                    })
                }
            }

        },
        handleDetailOrder(row) {  //详情
            this.$router.push({
                name: "InquiryDetails",
                params: {
                    detailList: row
                }
            })
        },
        handleCancelTheOrder(row) { //撤单
            var token = sessionStorage.getItem("token")
            axios.delete(`${baseUrl}/inquiry/deleteInquiry`, {
                params: {
                    id: row.id,
                },
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    // console.log("撤销", res)
                    // if(res.data.succ)
                    if (res.data.success == true) {
                        this.$message({
                            showClose: true,
                            message: '删除成功',
                            type: 'success'
                        });
                        this.getinquiryList()

                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        },

        handleSizeChange(val) { //分页
            var token = sessionStorage.getItem("token");
            if (this.value1) {
                this.begTime = this.value1[0] || "";  //开始时间
                this.endTimes = this.value1[1] || ''; //结束时间
            } else {
                this.begTime = '';  //开始时间
                this.endTimes = ''; //结束时间
            }

            axios.get(`${baseUrl}/inquiry/inquiryList`,
                {
                    //参数列表
                    params: {
                        pageNo: val,
                        state: this.states,
                        begTime: this.begTime || '',
                        endTimes: this.endTimes || '',
                    },
                    //请求头配置  
                    headers: { 'authorization': token }
                }
            ).then(res => {
                // this.orderDetailList.push(res.data.result);
                // console.log("详情", res.data.result)
                this.orderDetailList = res.data.result.records
            }).catch(err => {
                console.error(err);
            })
        }
    }

}
</script>

<style lang="scss" scoped>
// ::v-deep .el-table tr:nth-child(2n-1) {
//     background: #F4F4F4;
// }

::v-deep .el-table_2_column_22 {
    text-align: center;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 649px;
    //     /*滚动条宽度*/
    height: 8px;
    //     /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    /*滚动条的背景区域的圆角*/

    background: rgba(153, 153, 153, 0.2);
    /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /*滚动条的圆角*/

    background: #999999;
    /*滚动条的背景颜色*/
}

.EnquiryCabinet {
    margin: 0 auto;
    // max-width: 1526px;
    // min-height: 666px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;

    .EnquiryServer {
        display: flex;
        align-items: center;
        padding-left: 32px;
        height: 40px;

        .orderNumber {
            width: 284px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;

            input {
                width: 100%;
                height: 100%;
                padding-left: 16px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #DCDFE6;
                cursor: pointer;
            }

            input:focus {
                outline: 1px solid #409EFF;
            }
        }

        .orderStatus {
            width: 284px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 32px;

            .el-select {
                width: 100%;
                height: 100%;
            }
        }

        .orderRangeDate {
            width: 284px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            // border: 1px solid #999999;
            margin-left: 32px;

            .el-date-editor {
                width: 100%;
                height: 100%;

            }

            ::v-deep .el-input__prefix {
                display: none;
            }
        }

        .server_Btn {
            width: 120px;
            height: 40px;
            background: #FFC13B;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            color: #2B2B2B;
            margin-left: 32px;
            cursor: pointer;
        }
    }

    .tables {
        width: 100%;
        margin-top: 60px;
        padding: 0 24px 32px;
        // min-height: 500px;

        ::v-deep .nana {
            text-align: center;
        }

        .el-button {
            // width: 72px;
            height: 24px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            // line-height: 24px;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            padding: 6px 12px;
        }

        .el-button:nth-child(2) {
            background: #D4252C;
            box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
        }


        ::v-deep .is-leaf {
            font-size: 14px;
            font-weight: 400;
            color: #707070;
        }

        ::v-deep .el-table__row {
            height: 84px;

            .el-table__cell {
                font-size: 14px;
                // font-weight: bold;
                color: #333333;

                .cell {
                    height: 24px;
                }
            }
        }

        .detailOrder {
            width: 48px;
            height: 24px;
            background: #4CAF50;
            box-shadow: 0px 3px 6px 1px rgba(76, 175, 80, 0.3);
            border-radius: 4px 4px 4px 4px;
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            padding: 0;
        }


    }

    .paging {
        display: flex;
        justify-content: flex-end;
        position: relative;
        // float: right;
        margin-top: 32px;
        // background-color: #FFFFFF;
    }
}
</style>
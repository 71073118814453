<template>
    <div>
        <!-- 询柜订单 -->
        <EnquiryCabinet_Component></EnquiryCabinet_Component>
    </div>
</template>

<script>
import EnquiryCabinet_Component from '../../components/PersonalCenter/EnquiryCabinet_Component.vue'
export default {
    name: "CounterInquiryOrder",
    components: { EnquiryCabinet_Component },
    data() {
        return {

        }
    },
}
</script>

<style>

</style>